import React from 'react';
import PropType from 'react-proptypes';
import styled from 'styled-components';

import { CardTitle } from '../Card';

const PostPreviewTitleContainer = styled(CardTitle)`
    margin: 0;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.headlinesColor};
`;

const BlogPostLink = styled.a`
    color: inherit;
    text-decoration: none;
    margin: 0;
`;

const PostPreviewTitle = ({ path, title, className }) => (
    <PostPreviewTitleContainer className={className}>
        <BlogPostLink href={path}>{title}</BlogPostLink>
    </PostPreviewTitleContainer>
);

PostPreviewTitle.propTypes = {
    path: PropType.string.isRequired,
    title: PropType.string.isRequired,
    className: PropType.string,
};

export default PostPreviewTitle;
