import React from 'react';
import styled from 'styled-components';
import PropType from 'react-proptypes';

import OldPostYearList from './OldPostYearList';

const OldPostListContainer = styled.div`
    margin: auto;
    max-width: 820px;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

const OldPostList = ({
    posts,
    onTagClick,
    onAuthorClick,
    linkToAuthorBlogPages,
}) => {
    const postsPerYear = posts.reduce((acc, post) => {
        const year = post.date.split('-')[0];
        return {
            ...acc,
            [year]: [...(acc[year] || []), post],
        };
    }, {});

    const years = Object.keys(postsPerYear)
        .sort()
        .reverse();
    return (
        <OldPostListContainer>
            {years.map(year => (
                <OldPostYearList
                    key={year}
                    year={year}
                    onAuthorClick={onAuthorClick}
                    linkToAuthorBlogPages={linkToAuthorBlogPages}
                    onTagClick={onTagClick}
                    posts={postsPerYear[year]}
                />
            ))}
        </OldPostListContainer>
    );
};

OldPostList.propTypes = {
    onAuthorClick: PropType.func,
    linkToAuthorBlogPages: PropType.bool,
    onTagClick: PropType.func.isRequired,
    posts: PropType.array.isRequired,
};

export default OldPostList;
