import React from 'react';
import PropType from 'react-proptypes';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import { Link } from 'gatsby';

import { Card } from '../Card';
import BlogTagList from './BlogTagList';
import PostPreviewTitle from './PostPreviewTitle';
import Authorship from './Authorship';
import WithBackgroundImage from '../Image/WithBackgroundImage';

const ImageLink = styled(Link)`
    overflow: hidden;
`;

const BlogCard = styled(Card)`
    display: flex;
    flex-direction: row;
    height: 15rem;
    margin: 1.25rem auto;
    padding: 0;
    max-width: 1024px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 860px) {
        width: auto;
        margin: 20px 10px;
        height: auto;
        flex-direction: column;
    }
`;

const BlogImage = styled(WithBackgroundImage)`
    z-index: 0;
    width: 370px;
    height: 15rem;
    @media (max-width: 860px) {
        width: auto;
        height: 12.19rem;
    }
`;

const BlogPostSummaryContainer = styled(Card)`
    background-color: white;
    width: 606px;
    padding: 1.875rem;
    @media (max-width: 860px) {
        padding: 20px;
        width: auto;
    }
`;

const Excerpt = styled.p`
    font-size: 0.9rem;
    font-family: Open Sans, sans-serif;
    color: ${({ theme }) => theme.yellowDark};
    margin-bottom: 0.625rem;
    overflow: hidden; /* hide text if it more than N lines  */
    /* for set '...' in absolute position */
    position: relative;
    line-height: 1.35rem;
    @media (min-width: 860px) {
        /* use this value to count block height */
        max-height: 2.7rem;
        /* fix problem when last visible word doesn't adjoin right side  */
        text-align: justify;
        /* place for '...' */
        padding-right: 1rem;
        &:before {
            /* points in the end */
            content: '...';
            /* absolute position */
            position: absolute;
            /* set position to right bottom corner of block */
            right: 0;
            bottom: 0;
        }
        &:after {
            /* points in the end */
            content: '';
            /* absolute position */
            position: absolute;
            /* set position to right bottom corner of text */
            right: 0;
            /* set width and height */
            width: 1rem;
            height: 1.35rem;
            margin-top: 0.15rem;
            /* bg color = bg color under block */
            background: white;
        }
    }
`;

const BlogPostSummary = translate()(
    ({
        onAuthorClick,
        linkToAuthorBlogPages,
        onTagClick,
        post: { title, path, tags, excerpt, authors, date },
        className,
    }) => (
        <BlogPostSummaryContainer className={className}>
            <PostPreviewTitle path={path} title={title} />
            <Excerpt
                dangerouslySetInnerHTML={{
                    __html: excerpt,
                }}
            />
            <BlogTagList tags={tags} onTagClick={onTagClick} />
            <Authorship
                date={date}
                authors={authors}
                linkToAuthorBlogPages={linkToAuthorBlogPages}
                onAuthorClick={onAuthorClick}
            />
        </BlogPostSummaryContainer>
    ),
);

BlogPostSummary.propTypes = {
    post: PropType.shape({
        title: PropType.string.isRequired,
        path: PropType.string.isRequired,
    }).isRequired,
    className: PropType.string,
    onAuthorClick: PropType.func,
    linkToAuthorBlogPages: PropType.bool,
    onTagClick: PropType.func.isRequired,
};

const PostPreview = ({
    onAuthorClick,
    onTagClick,
    post,
    linkToAuthorBlogPages,
}) => (
    <BlogCard>
        {(post.thumbnail_image || post.cover_image) && (
            <ImageLink aria-label={post.title} to={post.path}>
                <BlogImage
                    role="presentation"
                    image={post.thumbnail_image || post.cover_image}
                />
            </ImageLink>
        )}
        <BlogPostSummary
            post={post}
            onAuthorClick={onAuthorClick}
            linkToAuthorBlogPages={linkToAuthorBlogPages}
            onTagClick={onTagClick}
            isList
        />
    </BlogCard>
);

PostPreview.propTypes = {
    onAuthorClick: PropType.func,
    linkToAuthorBlogPages: PropType.bool,
    onTagClick: PropType.func.isRequired,
    post: PropType.shape({
        title: PropType.string.isRequired,
        path: PropType.string.isRequired,
        thumbnail_image: PropType.string,
        cover_image: PropType.string,
    }).isRequired,
};

export default PostPreview;
