import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import omit from 'lodash.omit';
import styled from 'styled-components';
import Media from 'react-media';
import { translate } from 'react-polyglot';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import AppBar from './AppBar';
import AuthorCard from './Blog/AuthorCard';
import Footer from './Footer';
import BlogPostPreview from './Blog/BlogPostPreview';
import LastBlogPostSummary from './Blog/LastBlogPostSummary';
import MaxWidthContainer from './MaxWidthContainer';
import BaseHeaderImage from './HeaderImage';
import OldPostList from './Blog/OldPostList';
import TopBar from './TopBar';
import withLocale from './i18n/withLocale';
import { Newsletter } from './ShortCodes/Newsletter';

const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
`;

const HeaderImage = styled(BaseHeaderImage)`
    > div:first-child {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }
`;

const LatestPostTitle = styled.h1`
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    margin: 4.375rem 0 3.125rem;
    font-size: 2.5rem;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 3.063rem;
    text-align: center;
    color: ${({ theme }) => theme.headlinesColor};
    @media (max-width: 600px) {
        font-size: 1.75rem;
        margin: 1.75rem 0 0.8rem;
    }
`;

const TagButton = styled.button`
    background-color: ${({ active, theme, children }) => {
        if (!active && children === 'popular') {
            return '#f3f3f3';
        }
        return active ? theme.orange : '#f3f3f3';
    }};
    color: ${({ active, theme, children }) => {
        if (!active && children === 'popular') {
            return theme.orange;
        }
        return active ? 'white' : theme.tag;
    }};
    border: ${({ theme, children }) => {
        if (children === 'popular') {
            return `solid ${theme.orange} 2px`;
        }
        return `solid #dcdcdc 1px`;
    }};
    cursor: pointer;
    box-sizing: border-box;
    height: 1.625rem;
    margin: 0.25rem;
    font-style: italic;
    padding: 0.25rem 0.625rem;
    &:before {
        content: '# ';
    }
`;

const Tags = styled.div`
    text-align: center;
    max-width: 1024px;
    margin: 0px auto 40px;
`;

const OlderPosts = styled.h2`
    text-align: center;
    color: ${({ theme }) => theme.headlinesColor};
    font-weight: 400;
    margin: 3rem 0;
    opacity: 0.7;
`;

const NoPostsBy = OlderPosts;

const AuthorContainer = styled.div`
    display: flex;
    max-width: 1024px;
    margin: 1.25rem auto;
`;

class BlogPage extends Component {
    state = {
        selectedTag: null,
    };

    componentDidMount() {
        if (!window) {
            return;
        }
        const tag = window.location.hash;
        if (!tag == '#') {
            return;
        }
        this.handleSelectTag(tag.substring(1));
    }

    selectTag(selectedTag) {
        return () => {
            this.handleSelectTag(selectedTag);
        };
    }

    handleSelectTag = selectedTag => {
        if (this.state.selectedTag === selectedTag) {
            this.setState({ selectedTag: null });
            if (window) {
                window.location.hash = '';
            }
            return;
        }
        this.setState({ selectedTag });
        if (window) {
            window.location.hash = '#' + selectedTag;
        }
    };

    render() {
        const { data, locale, author, t } = this.props;

        const team = data.team.edges.reduce(
            (acc, { node: { frontmatter } }) => ({
                ...acc,
                [frontmatter.name]: frontmatter,
            }),
            {},
        );

        const allPosts = data.posts.edges.map(({ node }) => ({
            ...omit(node, 'frontmatter'),
            ...node.frontmatter,
            authors: node.frontmatter.authors.map(author => team[author]),
        }));
        const { selectedTag } = this.state;
        const tags = allPosts
            .reduce(
                (acc, post) => Array.from(new Set([...acc, ...post.tags])),
                [],
            )
            .sort();

        let selectedPosts = allPosts;

        if (selectedTag) {
            selectedPosts = selectedPosts.filter(
                post => post.tags.indexOf(selectedTag) !== -1,
            );
        }

        const authorFullName = author
            ? team[author]
                ? team[author].fullname
                : ''
            : '';

        const [lastPost, ...remainingPosts] = selectedPosts;

        const recentPosts = remainingPosts.slice(0, 10);

        const oldPosts = remainingPosts.slice(10);

        return (
            <Container>
                <Helmet title={t('seo.blogTitle')}>
                    <html lang={locale} />
                    <meta
                        name="description"
                        content={t('seo.blogDescription')}
                    />
                    {lastPost && (
                        <link rel="preload" href={lastPost.image} as="image" />
                    )}
                </Helmet>
                <TopBar white role="presentation" />
                <header>
                    <AppBar />
                    {lastPost && (
                        <Link aria-label={lastPost.title} to={lastPost.path}>
                            <HeaderImage
                                role="presentation"
                                image={lastPost.cover_image}
                            />
                        </Link>
                    )}
                </header>
                <div role="main">
                    <MaxWidthContainer>
                        {lastPost && (
                            <LastBlogPostSummary
                                post={lastPost}
                                linkToAuthorBlogPages={true}
                                onTagClick={this.handleSelectTag}
                            />
                        )}
                        {selectedTag && selectedTag === 'react-admin' ? (
                            <Newsletter />
                        ) : null}
                        <LatestPostTitle>
                            {author
                                ? t('latestPostsBy', {
                                      author: authorFullName,
                                  })
                                : t('latestPosts')}
                        </LatestPostTitle>
                        {author && (
                            <AuthorContainer>
                                <AuthorCard
                                    key={author.name}
                                    author={team[author]}
                                />
                            </AuthorContainer>
                        )}

                        {author && !lastPost && (
                            <NoPostsBy>
                                {t('noPostsBy', {
                                    author: team[author].shortname,
                                })}
                            </NoPostsBy>
                        )}
                        <Media
                            query="(min-width: 600px)"
                            render={() => (
                                <Tags>
                                    {tags.map(tag => (
                                        <TagButton
                                            active={tag === selectedTag}
                                            onClick={this.selectTag(tag)}
                                            key={tag}
                                        >
                                            {tag}
                                        </TagButton>
                                    ))}
                                </Tags>
                            )}
                        />
                        <div>
                            {recentPosts.map(post => {
                                return (
                                    <BlogPostPreview
                                        key={post.id}
                                        post={post}
                                        linkToAuthorBlogPages={true}
                                        onTagClick={this.handleSelectTag}
                                    />
                                );
                            })}
                        </div>
                        {oldPosts.length === 0 ? (
                            <div style={{ marginBottom: '5rem' }} />
                        ) : (
                            <>
                                <OlderPosts>{t('olderPosts')}</OlderPosts>
                                <OldPostList
                                    posts={oldPosts}
                                    linkToAuthorBlogPages={true}
                                    onTagClick={this.handleSelectTag}
                                />
                            </>
                        )}
                    </MaxWidthContainer>
                </div>
                <Footer />
            </Container>
        );
    }
}

BlogPage.propTypes = {
    data: PropTypes.shape({
        team: PropTypes.shape({
            edges: PropTypes.arrayOf({
                node: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    frontmatter: PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        path: PropTypes.string.isRequired,
                        date: PropTypes.string.isRequired,
                        excerpt: PropTypes.string.isRequired,
                    }).isRequired,
                }),
            }),
        }),
        posts: PropTypes.shape({
            edges: PropTypes.arrayOf({
                node: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    frontmatter: PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        path: PropTypes.string.isRequired,
                        date: PropTypes.string.isRequired,
                        excerpt: PropTypes.string.isRequired,
                    }).isRequired,
                }),
            }),
        }),
    }).isRequired,
    author: PropTypes.string.isRequired,
    locale: PropTypes.string,
    t: PropTypes.func.isRequired,
};

export default withLocale(translate()(BlogPage));
