import React from 'react';
import styled from 'styled-components';
import PropType from 'react-proptypes';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import PostPreviewTitle from './PostPreviewTitle';
import BlogTagList from './BlogTagList';
import { AuthorshipLight } from './Authorship';

const OldPostContainer = styled.div`
    display: flex;
`;

const OldPostTextContainer = styled.div`
    max-width: 680px;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 1.25rem;
    margin: 1.25rem 0;
    font-size: 0.8125rem;
    color: ${({ theme }) => theme.text};

    @media (max-width: 600px) {
        width: auto;
        margin: 0.625rem 0.3125rem;
        padding-bottom: 0;
    }
`;

const OldPostTitle = styled(PostPreviewTitle)`
    opacity: 0.7;
    color: #121212;
`;

const Info = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const OldPostBlogTags = styled(BlogTagList)`
    @media (max-width: 600px) {
        margin: 0.3125rem 0 0 0;
    }
`;

const OldPostAuthorshipLight = styled(AuthorshipLight)`
    @media (max-width: 600px) {
        margin: 0.3125rem 0;
    }
`;

const ImageLink = styled(Link)`
    width: 100px;
    min-width: 100px;
`;

const BlogImage = styled(Image)`
    width: 80px;
    height: 60px;
    margin: 1.25rem 0;
    @media (max-width: 600px) {
        margin: 0.8rem;
    }
`;

const OldPost = ({
    onAuthorClick,
    onTagClick,
    linkToAuthorBlogPages,
    post: { thumbnail_image, cover_image, path, title, tags, date, authors },
}) => (
    <OldPostContainer>
        {(thumbnail_image || cover_image) &&
            (thumbnail_image || cover_image).childImageSharp && (
                <ImageLink aria-label={title} to={path}>
                    <BlogImage
                        role="presentation"
                        fluid={
                            (thumbnail_image || cover_image).childImageSharp
                                .fluid
                        }
                    />
                </ImageLink>
            )}
        <OldPostTextContainer>
            <OldPostTitle path={path} title={title} />
            <Info>
                <OldPostBlogTags tags={tags} onTagClick={onTagClick} />
                <OldPostAuthorshipLight
                    date={date}
                    authors={authors}
                    linkToAuthorBlogPages={linkToAuthorBlogPages}
                    onAuthorClick={onAuthorClick}
                />
            </Info>
        </OldPostTextContainer>
    </OldPostContainer>
);

OldPost.propTypes = {
    onAuthorClick: PropType.func,
    linkToAuthorBlogPages: PropType.bool,
    onTagClick: PropType.func.isRequired,
    post: PropType.shape({
        path: PropType.string.isRequired,
        title: PropType.string.isRequired,
        tags: PropType.arrayOf(PropType.string).isRequired,
        date: PropType.string.isRequired,
        authors: PropType.array,
        thumbnail_image: PropType.object,
        cover_image: PropType.object,
    }),
};

export default OldPost;
