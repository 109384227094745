import React from 'react';
import PropType from 'react-proptypes';
import styled from 'styled-components';
import { translate } from 'react-polyglot';

import { Card } from '../Card';
import BlogTagList from './BlogTagList';
import Authorship from './Authorship';
import { PrimaryButton } from '../Button';
import PostPreviewTitle from './PostPreviewTitle';

const ViewMoreButton = styled(PrimaryButton)`
    width: 232px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    position: absolute;
    bottom: 1.875rem;
    right: 2.5rem;
    @media (max-width: 860px) {
        position: static;
        align-self: center;
        margin: 1.875rem 0;
    }
`;

const BlogPostSummaryContainer = styled(Card)`
    background-color: white;
    width: 606px;
    padding: 1.875rem;
    @media (max-width: 860px) {
        padding: 20px;
        width: auto;
    }
`;

const Excerpt = styled.p`
    font-size: 0.9rem;
    font-family: Open Sans, sans-serif;
    color: ${({ theme }) => theme.yellowDark};
    margin-bottom: 0.625rem;
    overflow: hidden; /* hide text if it more than N lines  */
    /* for set '...' in absolute position */
    position: relative;
    line-height: 1.35rem;
    @media (min-width: 860px) {
        /* use this value to count block height */
        max-height: 2.7rem;
        /* fix problem when last visible word doesn't adjoin right side  */
        text-align: justify;
        /* place for '...' */
        padding-right: 1rem;
        &:before {
            /* points in the end */
            content: '...';
            /* absolute position */
            position: absolute;
            /* set position to right bottom corner of block */
            right: 0;
            bottom: 0;
        }
        &:after {
            /* points in the end */
            content: '';
            /* absolute position */
            position: absolute;
            /* set position to right bottom corner of text */
            right: 0;
            /* set width and height */
            width: 1rem;
            height: 1.35rem;
            margin-top: 0.15rem;
            /* bg color = bg color under block */
            background: white;
        }
    }
`;

const BlogPostSummary = translate()(
    ({
        onAuthorClick,
        onTagClick,
        post: { title, path, tags, excerpt, authors, date },
        className,
    }) => (
        <BlogPostSummaryContainer className={className}>
            <PostPreviewTitle path={path} title={title} />
            <Excerpt>{excerpt}</Excerpt>
            <BlogTagList tags={tags} onTagClick={onTagClick} />
            <Authorship
                date={date}
                authors={authors}
                onAuthorClick={onAuthorClick}
            />
        </BlogPostSummaryContainer>
    ),
);

BlogPostSummary.propTypes = {
    post: PropType.shape({
        title: PropType.string.isRequired,
        path: PropType.string.isRequired,
    }).isRequired,
    className: PropType.string,
    onAuthorClick: PropType.func.isRequired,
    onTagClick: PropType.func.isRequired,
};

const LastPostTitle = styled(PostPreviewTitle)`
    font-size: 1.75rem;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 2.438rem;
    margin-top: 0.625rem;
    margin-bottom: 0.3125rem;
`;

const LastBlogPostSummaryContainer = styled(BlogPostSummaryContainer)`
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
    margin-bottom: -10rem;
    top: -10rem;
    min-height: 12.5rem;
    border-bottom: solid 1px #dcdcdc;
    @media (max-width: 600px) {
        position: static;
        width: auto;
        margin-bottom: 0;
        height: auto;
        padding-bottom: 0;
    }
`;

const LastBlogPostSummary = translate()(
    ({
        onAuthorClick,
        linkToAuthorBlogPages,
        onTagClick,
        post: { title, path, tags, excerpt, authors, date },
        t,
        className,
    }) => (
        <LastBlogPostSummaryContainer className={className}>
            <LastPostTitle path={path} title={title} />
            <Excerpt>{excerpt}</Excerpt>
            <BlogTagList tags={tags} onTagClick={onTagClick} />
            <Authorship
                date={date}
                authors={authors}
                onAuthorClick={onAuthorClick}
                linkToAuthorBlogPages={linkToAuthorBlogPages}
            />
            <ViewMoreButton to={path}>{t('readMore')}</ViewMoreButton>
        </LastBlogPostSummaryContainer>
    ),
);

LastBlogPostSummary.propTypes = {
    onAuthorClick: PropType.func,
    linkToAuthorBlogPages: PropType.bool,
    onTagClick: PropType.func.isRequired,
    post: PropType.shape({
        title: PropType.string.isRequired,
        path: PropType.string.isRequired,
    }).isRequired,
};

export default LastBlogPostSummary;
