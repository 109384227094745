import React from 'react';
import PropTypes from 'react-proptypes';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import enMessages from '../../content/en';
import frMessages from '../../content/fr';

import withSetLocale from '../components/i18n/withSetLocale';
import Layout from '../components/layout';
import BlogPage from '../components/BlogPage';

const Template = ({
    data,
    pageContext: {
        locale,
        author: [author],
    },
}) => {
    return (
        <Layout>
            <I18n
                locale={locale}
                messages={locale === 'en' ? enMessages : frMessages}
            >
                <BlogPage locale={locale} data={data} author={author} />
            </I18n>
        </Layout>
    );
};

Template.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Template);

export const query = graphql`
    query AuthorQuery($author: [String]!) {
        posts: allMdx(
            sort: { order: DESC, fields: [fileAbsolutePath] }
            filter: {
                fileRelativePath: { glob: "**/blog/**" }
                frontmatter: { authors: { in: $author } }
            }
        ) {
            edges {
                node {
                    id
                    path
                    date
                    frontmatter {
                        title
                        authors
                        tags
                        excerpt
                        cover_image {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1920, quality: 80) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        thumbnail_image {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 370, quality: 80) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        team: allMdx(filter: { fileRelativePath: { glob: "**/team/**" } }) {
            edges {
                node {
                    id
                    frontmatter {
                        name
                        shortname
                        fullname
                        link
                        github
                        twitter
                        picture
                        picture_sp
                        biography
                    }
                }
            }
        }
    }
`;
