import React from 'react';
import PropType from 'react-proptypes';
import styled from 'styled-components';

import OldPost from './OldPost';

const YearTitle = styled.h2`
    font-size: 1.75rem;
    color: ${({ theme }) => theme.blue};
    font-weight: 300;
    margin-bottom: 1.875rem;
    margin-top: 4.375rem;
    padding-left: 1.25rem;
    @media (max-width: 600px) {
        margin-top: 3.125rem;
        font-size: 1.313rem;
        text-align: center;
        padding-left: 0;
    }
`;

const OldPostYearList = ({
    year,
    posts,
    onTagClick,
    onAuthorClick,
    linkToAuthorBlogPages,
}) => {
    const currentYear = new Date().getFullYear();

    return (
        <div>
            {year != currentYear && <YearTitle>{year}</YearTitle>}
            <div>
                {posts.map(post => (
                    <OldPost
                        key={post.id}
                        post={post}
                        onAuthorClick={onAuthorClick}
                        linkToAuthorBlogPages={linkToAuthorBlogPages}
                        onTagClick={onTagClick}
                    />
                ))}
            </div>
        </div>
    );
};

OldPostYearList.propTypes = {
    year: PropType.string.isRequired,
    onAuthorClick: PropType.func,
    linkToAuthorBlogPages: PropType.bool,
    onTagClick: PropType.func.isRequired,
    posts: PropType.array.isRequired,
};

export default OldPostYearList;
